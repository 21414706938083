@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto:ital,wght@1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nokora:wght@300;400;700&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Hanuman:wght@300;400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Moul:wght@400&display=swap"); */
@font-face { font-family: Moul; src: url(/static/media/KHMERMEF2.65609bf6.ttf); } 
@font-face { font-family: Hanuman; src: url(/static/media/Hanuman-Regular.8cb3ec58.ttf); } 
@font-face { font-family: Hanuman; font-weight: bold; src: url(/static/media/Hanuman-Bold.3151dac4.ttf);}
@font-face { font-family: MEF1; src: url(/static/media/KHMERMEF1.6eb44b46.ttf); } 
/* @font-face { font-family: Hanuman; font-weight: bold; src: url('./assets/fonts/KHMERMEF1.ttf');} */
@font-face { font-family: Bokor; src: url(/static/media/Bokor-Regular.c4d9eb79.ttf); }
@font-face { font-family: NotoSansKhmer; src: url(/static/media/NotoSansKhmer-Regular.fc72fab5.ttf); } 

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

th {
  font-family: "Rajdhani, Hanuman, Nokora, Bokor, sans-serif" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.swal-text {
 color:black;
}
.swal-button {
  color: white;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

input,
textarea,
button {
	border-radius: 3px !important;
}

.my-custom-date-picker {
	width: 100%;
	min-width: 180px;
	height: 48px;
	border-radius: 3px;
	cursor: pointer;
}

.my-custom-date-picker-null {
	width: 100%;
	min-width: 180px;
	height: 48px;
	border-radius: 3px;
	cursor: pointer;
	color: white;
}

.DatePicker {
	z-index: 99999;
}

.DatePicker .Calendar__weekDay {
	color: black;
	text-decoration: none !important;
}

.DatePicker input {
	font-size: 16px !important;
}

.show-only-printing {
	display: none !important;
}

@media print {
	body {
		font-family: 'Rajdhani, Hanuman, sans-serif, Moul';
	}

	.pagebreak {
		page-break-before: always;
	} /* page-break-after works, as well */

	.hidden-print {
		display: none !important;
	}

	.show-only-printing {
		display: block !important;
	}

	.print-footer {
		position: relative;
		top: -20px;

		height: 10px;
	}

	.table-company-proposal-list td {
		padding-left: 8px !important;
		padding-right: 8px !important;
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}

	.market-data-tab {
		border: none;
	}
}

.td-as-th {
	font-weight: 600;
	color: #4a5568;
}

.datetimePickerForm,
.datetimePickerFormError {
	width: 100%;
	height: 48px;
	font-size: 18px;
}
.datetimePickerForm input:nth-child(2) {
	margin-left: 10px;
}
.datetimePickerFormError .react-datetime-picker__wrapper {
	border-color: #fc8181 !important;
	box-shadow: 0 0 0 1px #fc8181;
	border-width: 1px;
	border-radius: 2px;
}

.datetimePickerForm .react-datetime-picker__wrapper {
	border-color: #cbd5e0 !important;
	border-radius: 2px;
}

.datetimePickerForm,
.datetimePickerFormError input {
	margin-right: 2px;
}

.dp-container .DatePicker {
	width: 100%;
}

.commentContainer {
	/* page-break-inside:avoid !important; */
}

@media screen and (max-width: 470px) {
	.hide-mobile {
		display: none !important;
	}
}

.DatePicker {
	z-index: unset !important;
}

.DatePicker__calendarContainer {
	z-index: 100 !important;
}

.react-datetime-picker__inputGroup {
	padding-left: 20px !important;
}

@media only screen and (max-width: 600px) {
	.loginButtonContainer {
		flex-direction: column !important;
	}
}

.tabIcon {
	font-size: 18px;
}

/* Responsive Table */
.responsive-table-container thead tr {
	border-bottom: 0.5px solid rgba(189, 195, 199, 0.5) !important;
}

.responsive-table-container thead th {
	min-height: 40px;
	padding: 16px 24px 16px 24px;
}

.responsive-table-container tfoot th {
	min-height: 40px;
	padding: 16px 24px 16px 24px;
}

.responsive-table-container td {
	min-height: 40px;
	padding: 16px 24px 16px 24px;
}

.responsive-table-container tbody tr {
	border-bottom: 0.5px solid rgba(189, 195, 199, 0.5) !important;
}

.responsive-table-container .loading-responsive-tr td {
	padding: 0;
}

.responsive-table-container tbody tr:hover {
	background-color: #e2e8f0;
	cursor: pointer;
}

.responsive-table-container .zoom-td:hover {
	cursor: zoom-in !important;
	background-color: rgba(52, 152, 219, 0.1) !important;
}

.responsive-table-container .new {
	background-color: #edf2f7;
}

.responsive-table-container .read {
	background-color: white;
}

@media screen and (max-width: 40em) {
	.responsive-table-container .new {
		background-color: rgba(44, 62, 80, 0.3);
	}

	.responsive-table-container tbody tr:hover {
		background-color: white;
		cursor: pointer;
	}

	.responsive-table-container .new:hover {
		background-color: rgba(44, 62, 80, 0.3);
		cursor: pointer;
	}

	.responsiveTable tbody tr {
		transition: 0.3s;
		margin-bottom: 16px;
		border: unset !important;
		background: #fff;
		box-shadow: 0 -1px 30px 0px rgba(90, 116, 148, 0.2);
		border-radius: 4px;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}
	.responsive-table-container td {
		min-height: 40px;
		padding: 4px 24px 4px 24px;
	}

	.responsive-table-container .responsive-footer {
		display: none;
	}

	.responsive-table-container .loading-responsive-tr td {
		min-height: 40px;
		padding: 0;
		margin: 0;
	}

	.responsive-header-for-table {
		flex-direction: column;
		padding: 0 !important;
	}
	.responsive-header-for-table .panel-tab-container {
		width: 100%;
	}
	.responsive-header-for-table .panel-tab {
		width: 100%;
	}

	.responsive-header-for-table .mobile-header-menu {
		display: flex;
	}

	.responsive-header-for-table .header-menu {
		display: none;
	}
}

/* End Responsive Table */

/* Mobile proposal detail screen */
@media screen and (max-width: 40em) {
	.proposal-detail-menu-header .menu-title {
		display: none;
	}
	.step-file-detail {
		flex-direction: column;
	}

	.step-file-detail .list-of-step {
		border-top: 1px solid rgba(90, 116, 148, 0.2);
		border-bottom: 1px solid rgba(90, 116, 148, 0.2);
		padding-bottom: 16px;
		margin-top: 16px;
		margin-bottom: 8px;
	}
}
/* End Mobile proposal detail screen */

.text-area-viewer {
	width: 100%;
	white-space: pre-wrap;
	font-size: 16px;
	font-family: Rajdhani, Hanuman, sans-serif, Moul !important;
}

/* Market Development Data */
/* Start */
.Spreadsheet__header,
.Spreadsheet__cell {
	max-width: 125px !important;
	min-width: 125px !important;
	width: 125px !important;
}

.spreadsheet_custom_header_report th,
.spreadsheet_custom_header_report td,
.footer-table_report td {
	max-width: 155px;
	min-width: 155px;
	width: 155px;
	vertical-align: middle;
	text-align: center;
	border: 1px solid hsl(2deg, 0%, 91%);
	font-weight: bold;
	color: #2c3e50;
}

.spreadsheet_custom_header th,
.spreadsheet_custom_header td,
.footer-table td {
	max-width: 125px;
	min-width: 125px;
	width: 125px;
	vertical-align: middle;
	text-align: center;
	border: 1px solid hsl(2deg, 0%, 91%);
	font-weight: bold;
	color: #2c3e50;
}

.spreadsheet_custom_header_report {
	margin-left: 0px !important;
	background-color: #fafafa;
}

.spreadsheet_custom_header {
	margin-left: 0px !important;
	background-color: #fafafa;
}

.spreadsheet_container {
	overflow-x: scroll !important;
	margin-left: 200px;
}

.product-title-cell {
	max-width: 200px !important;
	min-width: 200px !important;
	width: 200px !important;
	position: absolute !important;
	margin-left: -200px !important;
	height: 151px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #3498db66;
	border: 1px solid #cbd5e0 !important;
	font-weight: bold;
	color: #2c3e50;
}

.total-cell {
	max-width: 200px !important;
	min-width: 200px !important;
	width: 200px !important;
	position: absolute !important;
	margin-left: -200px !important;
	height: 34px !important;
	display: flex;
	align-items: center;
	background-color: #f7fafc !important;
	border: 1px solid hsl(2deg, 0%, 91%);
	font-weight: bold;
	color: rgba(44, 62, 80, 1);
	margin-top: -8px;
}

.product-list {
	max-width: 200px !important;
	min-width: 200px !important;
	width: 200px !important;
	position: absolute !important;
	margin-left: -200px !important;
}

.background-light-blue {
	background-color: #3498db66;
	border: 1px solid #cbd5e0 !important;
}

.background-light-ocean {
	background-color: #3498db33;
	border: 1px solid #cbd5e0 !important;
}

.background-light-silver {
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
}

.background-light-green {
	background-color: #27ae6033;
	border: 1px solid #cbd5e0 !important;
}

.background-light-orange {
	background-color: #f39c1233;
	border: 1px solid #cbd5e0 !important;
}

.background-light-pink {
	background-color: #e056fd66;
	border: 1px solid #cbd5e0 !important;
}

.background-light-yellow {
	background-color: #f6e58d66;
	border: 1px solid #cbd5e0 !important;
}

.background-light-purple {
	background-color: #686de066;
	border: 1px solid #cbd5e0 !important;
}

.background-light-carrot {
	background-color: #e67e2266;
	border: 1px solid #cbd5e0 !important;
}

.background-light-concrete {
	background-color: #95a5a666;
	border: 1px solid #cbd5e0 !important;
}

.headcol {
	position: absolute;
	width: 100px;
	margin-left: -100px;
}

.Spreadsheet__header {
	position: fixed;
	margin-left: -200px;
}

.Spreadsheet__table th,
td {
	height: 30px !important;
}

.responsive-table-container td {
	height: auto !important;
}

.footer-table {
	margin-top: -8px;
	background-color: #f7fafc;
}

.Spreadsheet__cell {
	text-align: right !important;
	font-weight: 400;
}

.spreadsheet_container .active-row {
	background-color: #2f3542;
}
.spreadsheet_container .active-row p {
	color: white !important;
}

.spreadsheet_custom_header .active-column {
	background-color: #2f3542;
	color: white !important;
}

.spreadsheet_container .Spreadsheet__cell--readonly {
	font-weight: 400;
	text-align: right;
	color: #2d3748;
	background-color: #f7fafc;
}

.Spreadsheet__cell--readonly {
	background-color: #ffffff;
}

.narrow-space-table th {
	padding: 16px 0px;
}

.narrow-space-table td {
	padding: 16px 0px;
}

.singleLine {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.smallFont {
	font-size: small;
}

.highlighted-row {
	background-color: #cacbcc !important;
	font-weight: bold;
}

.ews-container {
	overflow-x: scroll !important;
	margin-left: 400px;
}

.ews-container .Spreadsheet__cell--readonly {
	font-weight: 400;
	color: #2d3748;
	background-color: #f7fafc;
}

.ews-container .active-row {
	background-color: #2f3542;
}

.ews-container .active-row p {
	color: white !important;
}

.ews-background-light-silver {
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	padding-left: 12px;
	padding-right: 12px;
}

.ews-template-label {
	max-width: 400px !important;
	min-width: 400px !important;
	width: 400px !important;
	position: absolute !important;
	margin-left: -400px !important;
}

.ews-1-row-head {
	max-width: 400px !important;
	min-width: 400px !important;
	width: 400px !important;
	position: absolute !important;
	margin-left: -400px !important;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	font-weight: bold;
	color: #2c3e50;
	text-align: center;
}

.ews-2-row-head {
	max-width: 400px !important;
	min-width: 400px !important;
	width: 400px !important;
	position: absolute !important;
	margin-left: -400px !important;
	height: 69px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	font-weight: bold;
	color: #2c3e50;
	text-align: center;
}

.mtpl_container {
	overflow-x: scroll !important;
	margin-left: 400px;
}

.mtpl_container .Spreadsheet__cell--readonly {
	font-weight: 400;
	color: #2d3748;
	background-color: #f7fafc;
}

.mtpl_container .active-row {
	background-color: #2f3542;
}

.mtpl_container .active-row p {
	color: white !important;
}

.mtpl_label_head {
	max-width: 400px !important;
	min-width: 400px !important;
	width: 400px !important;
	position: absolute !important;
	margin-left: -400px !important;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	font-weight: bold;
	color: #2c3e50;
	text-align: center;
}

.mtpl_background_silver {
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	padding-left: 12px;
	padding-right: 12px;
}

.mtpl_template_label {
	max-width: 400px !important;
	min-width: 400px !important;
	width: 400px !important;
	position: absolute !important;
	margin-left: -400px !important;
}

.natcat_container {
	overflow-x: scroll !important;
	margin-left: 200px;
}

.natcat_container .Spreadsheet__cell--readonly {
	font-weight: 400;
	color: #2d3748;
	background-color: #f7fafc;
}

.natcat_container .active-row {
	background-color: #2f3542;
}

.natcat_container .active-row p {
	color: white !important;
}

.natcat_label_head {
	max-width: 200px !important;
	min-width: 200px !important;
	width: 200px !important;
	position: absolute !important;
	margin-left: -200px !important;
	height: 69px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	font-weight: bold;
	color: #2c3e50;
	text-align: center;
}

.natcat_background_silver {
	background-color: #95a5a633;
	border: 1px solid #cbd5e0 !important;
	padding-left: 12px;
	padding-right: 12px;
}
/* End */

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

/* 3 */


.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
/* Styles go here */

body {
  margin: 0 auto;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
}

@media print {

  .page-footer {
    position: fixed;
    background-color: white;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #62B3ED;
  }

  .footer, .t-foot {
      background: #fff;
  }

  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
    border-bottom: 1px solid black; /* for demo */
  }
  
  .page {
    page-break-after: always;
  }
  
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  
  button {display: none;}
  
  body {
    margin: 0;
    border: 0;
    background-color: white !important;
    zoom: 80%;
  }
} 
  
  
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
.my-table tbody tr:hover {
	background-color: #ecf0f1;
}

.my-table tr td:last-child {
	right: -2px !important;
}

@media screen and (max-width: 470px) {
    .my-table .table-header .title{
        display: none;
    }
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.pagination ul {
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
}

.pagination li {
	display: inline-block;
	/* padding: 10px; */
	border-radius: 4px;
}

.pagination li a {
	color: var(--chakra-colors-text-gray);
	cursor: pointer;
	font-weight: 500;
	padding: 14px;

	background-color: #ecf0f1;
	margin: 1px;
}

.pagination .active a {
	color: var(--chakra-colors-primary-dark);
	font-weight: bold;
}

.pagination a:hover {
	background-color: #bdc3c7;
}

tr {
	page-break-inside: avoid;
	page-break-after: auto;
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .avatarWraper:hover .remove {
    display: block !important;
  }

  tr {
    page-break-inside:avoid; page-break-after:auto 
  }
.viewContainer:hover .remove{
    display: block !important;
}

.can-not-assign-department button{
    display: none;
}
.disableForm {
	pointer-events: none;
	opacity: 0.5;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.disableFormClear {
	pointer-events: none;
	opacity: 1;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.disableButton {
	pointer-events: none;
	opacity: 0.8;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }
.pagination ul {
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
}

.pagination li {
	display: inline-block;
	border-radius: 4px;
}

.pagination li a {
	color: var(--chakra-colors-text-gray);
	cursor: pointer;
	font-weight: 500;
	padding: 14px;
	background-color: #ecf0f1;
	margin: 1px;
}

.pagination .active a {
	color: var(--chakra-colors-primary-dark);
	font-weight: bold;
}

.pagination a:hover {
	background-color: #bdc3c7;
}

.avatarWraper:hover .remove {
	display: block !important;
}

tr {
	page-break-inside: avoid;
	page-break-after: auto;
}

.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .leave-detail-container div:hover{
    background-color: rgba(236, 240, 241,.5);
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  /* .leave-detail-container div:hover{
    background-color: rgba(236, 240, 241,.5);
  } */
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .leave-detail-container div:hover{
    background-color: rgba(236, 240, 241,.5);
  }
/* 
  .agent_tab div{
    background-color: white !important;
  } */

  .agent_tab button{
    border-radius: 0 !important;
  }

  .agent_tab {
    page-break-inside:avoid !important;
  }

  .agentConditionYesNo .chakra-checkbox__control{
    color: #3498DA !important;
  }

  .agentConditionYesNo .chakra-checkbox__label{
    opacity: 1 !important;
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  /* .leave-detail-container div:hover{
    background-color: rgba(236, 240, 241,.5);
  } */
.pagination ul {
	display: inline-block;
	padding-left: 15px;
	padding-right: 15px;
}

.pagination li {
	display: inline-block;
	border-radius: 4px;
}

.pagination li a {
	color: var(--chakra-colors-text-gray);
	cursor: pointer;
	font-weight: 500;
	padding: 14px;

	background-color: #ecf0f1;
	margin: 1px;
}

.pagination .active a {
	color: var(--chakra-colors-primary-dark);
	font-weight: bold;
}

.pagination a:hover {
	background-color: #bdc3c7;
}

.content-detail {
	margin-top: 20px;
}

.subcontent-detail {
	border: 1px solid #dbdbdb;
}

.tabs {
	margin-left: 16px;
	margin-right: 16px;
}

.content-tab {
	padding: 32px;
}

@media print {
	.content-detail {
		margin-top: 0px;
	}

	.subcontent-detail {
		border: 0px solid #dbdbdb;
	}

	.tabs {
		margin-left: 0px;
		margin-right: 0px;
	}

	.content-tab {
		padding: 0px;
	}
}


.printTableEmployee td{
    border: 1px solid black;
    text-align: left;
    padding: 8px;
}

.printTableEmployee th{
    border: 1px solid black;
    text-align: left;
    padding: 8px;
}


.printTableEmployee{
    width: 100%;
}

.textCenter{
    text-align: center !important;
}

.content-detail-leave {
	margin-top: 0px;
}

@media print {
	.content-detail-leave {
		margin-top: 20px;
	}
}

.pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination li {
  display: inline-block;
  /* padding: 10px; */
  border-radius: 4px;
}

.pagination li a {
  color: var(--chakra-colors-text-gray);
  cursor: pointer;
  font-weight: 500;
  padding: 14px;

  background-color: #ecf0f1;
  margin: 1px;
}

.pagination .active a {
  color: var(--chakra-colors-primary-dark);
  font-weight: bold;
}


.pagination a:hover {
  background-color: #bdc3c7;
}

.tg-0lax {
  vertical-align: middle !important;
  text-align: center !important;
  background-color: #ecf0f1;
  font-family: "MEF1"
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 10px;
  font-family: "MEF1"
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  padding: 4px 4px;
  word-break: normal;
  font-family: "MEF1"
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-weight: normal;
  overflow: hidden;
  padding: 4px 4px;
  word-break: normal;
  font-size: 10px !important;
  font-family: "MEF1"
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top
}

.tg input {
  font-size: 10px;
  height: 20px;
  padding: 2px;
  text-align: center;
}

.tableHeader {
  text-align: left !important;
  background-color: #ecf0f1;
}

/* 
  @media print {

    .tg {
      font-family: "MEF1" !important;
    }
  } */
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .avatarWraper:hover .remove {
    display: block !important;
  }

  tr {
    page-break-inside:avoid; page-break-after:auto 
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .avatarWraper:hover .remove {
    display: block !important;
  }

  tr {
    page-break-inside:avoid; page-break-after:auto 
  }
.pagination ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .pagination li {
    display: inline-block;
    /* padding: 10px; */
    border-radius: 4px;
  }

  .pagination li a{
    color: var(--chakra-colors-text-gray);
    cursor: pointer;
    font-weight: 500;
    padding: 14px;
    
    background-color: #ecf0f1;
    margin: 1px;
  }

  .pagination .active a{
    color: var(--chakra-colors-primary-dark);
    font-weight: bold;
  }


  .pagination a:hover {
    background-color: #bdc3c7;
  }

  .avatarWraper:hover .remove {
    display: block !important;
  }

  tr {
    page-break-inside:avoid; page-break-after:auto 
  }
